import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '@/layout'
import Login from '@/views/login'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: Layout,
    children: [
      {
        path: '/banner',
        name: 'Home',
        component: () => import('@/views/banner/index')
      },
      {
        path: '/message',
        name: 'Message',
        component: () => import('@/views/message/index')
      },
      {
        path: '/badge',
        name: 'Badge',
        component: () => import('@/views/badge/index')
      },
      {
        path: '/user',
        name: 'User',
        component: () => import('@/views/user/index')
      },
      {
        path: '/product/release',
        name: 'Product',
        component: () => import('@/views/product/index')
      },
      {
        path: '/product/examine',
        name: 'Product',
        component: () => import('@/views/product/index')
      },
      {
        path: '/product/reject',
        name: 'Product',
        component: () => import('@/views/product/index')
      },
      {
        path: '/product/file',
        name: 'Product',
        component: () => import('@/views/product/index')
      },
      {
        path: '/union/dynamic',
        name: 'dynamic',
        component: () => import('@/views/union/index')
      },
      {
        path: '/union/resources',
        name: 'resources',
        component: () => import('@/views/union/index')
      },
      {
        path: '/union/partner',
        name: 'partner',
        component: () => import('@/views/union/partner/index')
      },
      {
        path: '/poster',
        name: 'Poster',
        component: () => import('@/views/poster/index')
      },
      {
        path: '/price',
        name: 'Price',
        component: () => import('@/views/price/index')
      },
      {
        path: '/recharge',
        name: 'Recharge',
        component: () => import('@/views/recharge/index')
      },
      {
        path: '/withdrawal',
        name: 'Withdrawal',
        component: () => import('@/views/withdrawal/index')
      },
      {
        path: '/check',
        name: 'Check',
        component: () => import('@/views/check/index')
      },
      {
        path: '/report/user',
        name: 'User',
        component: () => import('@/views/report/user/index')
      },
      {
        path: '/report/project',
        name: 'Project',
        component: () => import('@/views/report/project/index')
      },
      {
        path: '/giftCard',
        name: 'GiftCard',
        component: () => import('@/views/giftCard/index')
      },
      {
        path: '/heigAgent',
        name: 'HeigAgent',
        component: () => import('@/views/heigAgent/index')
      },
      {
        path: '/heigAgentData',
        name: 'HeigAgentData',
        component: () => import('@/views/heigAgentData/index')
      },
      {
        path: '/serviceCenter',
        name: 'ServiceCenter',
        component: () => import('@/views/serviceCenter/index')
      },
      {
        path: '/serviceCenterData',
        name: 'serviceCenterData',
        component: () => import('@/views/serviceCenterData/index')
      },
      {
        path: '/data',
        name: 'Data',
        component: () => import('@/views/data/index')
      }
    ]
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  }
]

const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  if (to.name !== 'Login' && !sessionStorage.getItem('access_token')) next({ name: 'Login' })
  else if (to.name === 'Login' && sessionStorage.getItem('access_token')) next({ name: 'Home' })
  else next()
})

export default router
