<template>
  <div class="asideBarItem-container">
    <!-- 如果有二级 -->
    <el-submenu v-if="router.children?.length" :index="router.url">
      <template slot="title">
        <i :class="router.icon" />
        <span>{{ router.name }}</span>
      </template>
      <el-menu-item-group>
        <el-menu-item v-for="(child, index) in router.children" :key="index" :index="child.url">{{ child.name }}</el-menu-item>
      </el-menu-item-group>
    </el-submenu>
    <!-- 如果没有 就展示一级导航 -->
    <el-menu-item
      v-else
      :key="router.url"
      :index="router.url"
    >
      <i :class="router.icon" />
      {{ router.name }}
    </el-menu-item>
  </div>
</template>

<script>
export default {
  name: 'AsideBarItem',
  components: {},
  props: {
    router: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {}
  }
}
</script>
